export interface FontSizes {
  sm: number;
  md: number;
  lg: number;
}

export interface ChartColor {
  red: string;
  lightRed: string;
  green: string;
  lightGreen: string;
  blue_1: string;
  purple_1: string;
  yellow: string;
  green_1: string;
}

export interface BarColor {
  vivid_sky_blue: string;
  blue_munsell: string;
  curious_blue: string;
  bahama_blue: string;
  persian_green: string;
  atoll: string;
}

export const widthBreakpoints = [480, 768, 992, 1024, 1280, 1366, 1920];
export const mq = widthBreakpoints.map((bp) => `@media (min-width: ${bp}px)`);

export function genKeyframes(keyname: string) {
  return `@keyframes ${keyname}`;
}

export interface BasicTheme {
  barheight: string;
  sidebarBackgroundColor: string;
  sidebarSelected: string;
  backgroundColor: string;
  cardHeaderColor: string;
  primaryColorLightest: string;
  primaryColorDarkest: string;
  darkGray: string;
  dangerColor: string;
  codeColor: string;
  fontSizes: FontSizes;
  blue: string;
  secondaryBlue: string;
  white: string;
  black: string;
  secondaryBlack: string;
  gray: string;
  lightGray: string;
  chart: ChartColor;
  bar: BarColor;
  loginBackground: string;
  inputLogin: string;
  black_1: string;
}

export const themeA: BasicTheme = {
  barheight: "4rem",
  sidebarBackgroundColor: "#22232B",
  sidebarSelected: "#353844",
  backgroundColor: "#EEF1F8",
  cardHeaderColor: "#20A8D8",
  primaryColorDarkest: "#A21A91",
  primaryColorLightest: "#F1B0E9",
  codeColor: "#d63384",
  fontSizes: {
    sm: 10,
    md: 15,
    lg: 20,
  },
  // based on desgyn system
  blue: "#376EB5",
  secondaryBlue: "#3699FF",
  white: "#FFFFFF",
  black: "#22232B",
  dangerColor: "#F05454",
  secondaryBlack: "#303139",
  gray: "#A5AEC2",
  lightGray: "#C4CDE0",
  chart: {
    red: "#FC5A5A",
    lightRed: "#FFE5E5",
    green: "#20C997",
    lightGreen: "#DCFFF5",
    blue_1: "#3699FF",
    purple_1: "#7B61FF",
    yellow: "#FDAA09",
    green_1: "#20C997",
  },
  loginBackground: "#073572",
  inputLogin: "#254a81",
  black_1: "#1C1C24",
  darkGray: "#687484",
  bar: {
    vivid_sky_blue: "#01CEEB",
    blue_munsell: "#0A95A8",
    curious_blue: "#1486DC",
    bahama_blue: "#0964A9",
    persian_green: "#009687",
    atoll: "#0C7368",
  },
};

export const COLORS = {
  white: "#FFFFFF",
  cyan: "#00BBD5",
  light_grey_1: "#EEF1F8",
  light_grey_2: "#C4CDE0",
  grey: "#A5AEC2",
  dark_grey_1: "#8997A9",
  dark_grey_2: "#687484",

  black_1: "#1C1C24",
  black_2: "#13131A",
  black_3: "#000000",

  light_blue_1: "#E3F1FF",
  light_blue_2: "#CEE6FF",
  blue_1: "#3699FF",
  blue_2: "#1486DC",
  blue_3: "#376EB5",
  blue_4: "#24C4CE",
  blue_5: "#376EB5",

  green_1: "#20C997",
  green_2: "#169E50",
  green_3: "#CCDD1E",
  green_4: "#009687",
  green_5: "#2ECC71",
  light_green: "#DCFFF5",

  darkerGreen: "#1E8263",
  darkerGreen_2: "#158362",

  yellow: "#FDAA09",
  yellow_2: "#FFB800",
  yellow_3: "#FFA336",
  light_yellow: "#FFF2DA",
  light_yellow_2: "#FFF6E3",
  darkerYellow: "#b87d07",
  red: "#FC5A5A",
  red_1: "#F05454",
  red_2: "#ED7068",
  light_red: "#FFE5E5",
  light_red_2: "#FFDBD9",

  darkerRed: "#9A383A",

  secondaryBlue: "#3699FF",

  purple_1: "#7B61FF",
  purple_2: "#9C1AB1",

  brown_1: "#B89A8E",
  brown_2: "#7A5547",

  asphalt: "#34495E",
  midnight: "#2C3E50",

  card_background: "#34495E",
  card_title_background: "#2C3E50",
} as const;
