import React, { useCallback, useState } from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { RiComputerLine } from "react-icons/ri";
import {
  rapidsense_long,
  rapidsense_notext,
  logo_datacakraWhite,
  noImage_png,
  rapidsense_by_datacakra,
  no_profile_image_png,
  default_avatar_png,
} from "../../assets/images";
import { BasicTheme, widthBreakpoints } from "../../assets/theme";
import {
  DashboardRoutes,
  ListOfRoutes,
  ManagementRoutes,
} from "../../config/nav";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { toggleSidebar } from "../../store/sidebarStore";
import SidebarItem, { Props as SidebarItemProps } from "./SidebarItem";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { getUser, useLogin } from "../../hooks/auth";
import useSidebarStyles from "./style";
import { useTheme } from "react-jss";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import { storeUserData } from "../../store/userStore";
import { profile_svg } from "../../assets/icons/svg";
import { BaseIcon } from "../../assets/icons";
import { IconBaseProps } from "react-icons";
import { AxiosResponse } from "axios";
import { UserDataWOToken } from "../../api/credentialsAPI";
import { showProfileImage } from "../../api/profileAPI";
// import useGetCompanyPermissions from "../../hooks/company/useGetCompanyPermissions";
// import CookieMonster from "../../api/CookieMonster";
// import { KnownUsertype } from "../../api/credentialsAPI/usertype";
import { Link, useNavigate } from "react-router-dom";
import { getDefaultRoute } from "../../pages/Redirector";
import { convertAxiosResponseToString } from "../../assets/usefulFunctions";

interface Props {}

const StyledNavigationItemWrapper = styled.div`
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 3px solid #1c1c24;
  }
  overflow-y: auto;
`;

const nav: SidebarItemProps[] = [
  {
    Icon: RiComputerLine,
    parentURL: ListOfRoutes.dashboard,
    allowedUsers: ["admin", "superadmin", "operator"],
    title: "Dashboard",
    // screenType: "dashboard",
    kiddos: [
      {
        title: "Energy Meter",
        screenType: "energymeter",
        shrinkTitle: "EM",
        kiddoURL: DashboardRoutes.energyMeter,
      },
      {
        title: "OEE",
        screenType: "oee",
        shrinkTitle: "OEE",
        kiddoURL: DashboardRoutes.oee,
      },
      {
        title: "Water Level",
        screenType: "waterlevel",
        shrinkTitle: "WL",
        kiddoURL: DashboardRoutes.waterlevel,
      },
      {
        title: "Envirobox",
        screenType: "envirobox",
        shrinkTitle: "EV",
        kiddoURL: DashboardRoutes.envirobox,
      },
      {
        title: "Employee Tracker",
        screenType: "employeetracker",
        shrinkTitle: "ET",
        kiddoURL: DashboardRoutes.employeeTracker,
      },
    ],
  },
  {
    Icon: AiOutlineQrcode,
    parentURL: ListOfRoutes.management,
    title: "Management",
    // screenType: "management",
    kiddos: [
      {
        title: "Company",
        shrinkTitle: "Cm",
        allowedUsers: ["systemadmin"],
        kiddoURL: ManagementRoutes.company,
      },
      {
        title: "Account",
        shrinkTitle: "Ac",
        allowedUsers: ["systemadmin", "superadmin", "admin"],
        screenType: "account",
        kiddoURL: ManagementRoutes.account,
      },
      {
        title: "Gateway",
        shrinkTitle: "Gt",
        allowedUsers: ["systemadmin"],
        kiddoURL: ManagementRoutes.gateway,
      },
      {
        title: "Location",
        shrinkTitle: "Lc",
        allowedUsers: ["systemadmin", "superadmin", "admin", "officer"],
        screenType: "location",
        kiddoURL: ManagementRoutes.locations,
      },
      {
        title: "Area",
        shrinkTitle: "Ar",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "area",
        kiddoURL: ManagementRoutes.area,
      },
      {
        title: "Sensor Type",
        allowedUsers: ["systemadmin"],
        shrinkTitle: "ST",
        kiddoURL: ManagementRoutes.sensortypes,
      },
      {
        title: "Device",
        shrinkTitle: "Dv",
        allowedUsers: ["systemadmin", "superadmin", "admin", "officer"],
        screenType: "device",
        kiddoURL: ManagementRoutes.devices,
      },
      {
        title: "Alert",
        shrinkTitle: "Al",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "alert",
        kiddoURL: ManagementRoutes.alerts,
      },
      {
        title: "Machine",
        shrinkTitle: "Mc",
        allowedUsers: ["systemadmin", "superadmin", "admin", "officer"],
        screenType: "machine",
        kiddoURL: ManagementRoutes.machine,
      },
      {
        title: "Product",
        shrinkTitle: "Pd",
        allowedUsers: ["systemadmin", "superadmin", "admin", "officer"],
        screenType: "product",
        kiddoURL: ManagementRoutes.product,
      },
      {
        title: "Work Order",
        shrinkTitle: "WO",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "workorder",
        kiddoURL: ManagementRoutes.workorder,
      },
      {
        title: "Tag",
        shrinkTitle: "TA",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "employeetag",
        kiddoURL: ManagementRoutes.employeetag,
      },
      {
        title: "Employee Type",
        shrinkTitle: "ET",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "employeetype",
        kiddoURL: ManagementRoutes.employeetype,
      },
      {
        title: "Employee",
        shrinkTitle: "EP",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "employee",
        kiddoURL: ManagementRoutes.employee,
      },
      {
        title: "Shift",
        shrinkTitle: "SF",
        allowedUsers: ["superadmin", "admin", "officer"],
        screenType: "shift",
        kiddoURL: ManagementRoutes.shift,
      },
    ],
  },
  {
    Icon: (props: IconBaseProps) => (
      <BaseIcon
        src={profile_svg}
        strokeWidth={0.3}
        height="10"
        width="10"
        viewBox="0 0 48 48"
        stroke="currentColor"
        fill="currentColor"
      />
    ),
    parentURL: ListOfRoutes.profile,
    // allowedUsers: ["systemadmin", "admin"],
    title: "Profile",
  },
  {
    Icon: HiOutlineDocumentReport,
    parentURL: ListOfRoutes.reports,
    allowedUsers: ["superadmin", "admin", "officer"],
    screenType: "report",
    title: "Reports",
  },
];

const Sidebar: React.FunctionComponent<Props> = () => {
  const classes = useSidebarStyles();
  const theme = useTheme<BasicTheme>();
  const userData = useAppSelector(({ userStore }) => {
    return userStore.userData;
  });
  const navigate = useNavigate();
  const expand = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );
  const dispatch = useAppDispatch();

  const dimension = useWindowDimensions();

  const login = useLogin();

  const isGreaterThan768 = useMediaQuery("(min-width: 768px)");

  const showProfileImageCallBack = useCallback(
    async (userData: UserDataWOToken) => {
      await showProfileImage().then((res: AxiosResponse) => {
        if (res.data.byteLength > 43 && res.data.byteLength !== 3519) {
          let img = convertAxiosResponseToString(res);
          dispatch(
            storeUserData({
              ...(userData as UserDataWOToken),
              profileImage: img,
            }),
          );
        } else {
          dispatch(
            storeUserData({
              ...(userData as UserDataWOToken),
              profileImage: "",
            }),
          );
        }
      });
    },
    [],
  );

  React.useEffect(() => {
    showProfileImageCallBack(userData as UserDataWOToken);
  }, []);

  React.useEffect(() => {
    if (isGreaterThan768) {
      dispatch(toggleSidebar(dimension.width > widthBreakpoints[2]));
    }
    // eslint-disable-next-line
  }, [isGreaterThan768]);

  React.useEffect(() => {
    if (!userData) {
      login().then(({ id, ...restLoginRes }) =>
        getUser({ id: id }).then(({ ...restUser }) => {
          let dataUser = restUser.data.data[0];
          dispatch(storeUserData({ ...dataUser, ...restLoginRes }));
          showProfileImageCallBack({ ...dataUser, ...restLoginRes });
        }),
      );
    }
  }, [userData, login]);

  return (
    <div className={[classes.container, expand ? "expand" : ""].join(" ")}>
      <div
        className={[classes.header, expand ? "expand" : "shorten"].join(" ")}
      >
        <img
          src={expand ? rapidsense_by_datacakra : rapidsense_notext}
          alt="logo"
          onClick={() => navigate(getDefaultRoute(userData?.usertypeName))}
          style={{ transform: expand ? "scale(0.75)" : "", cursor: "pointer" }}
        />
      </div>
      {/* Logo Container */}
      <div
        className={[classes.logoContainer, expand ? "expand" : ""].join(" ")}
      >
        <div
          className={[classes.sidebarLogoWrapper, expand ? "expand" : ""].join(
            " ",
          )}
        >
          <img
            alt="profile"
            src={
              userData?.profileImage !== ""
                ? userData?.profileImage
                : default_avatar_png
            }
            // onError={(e) => (e.currentTarget.src = noImage_png)}
            className={[classes.sideBarLogo, expand ? "expand" : ""].join(" ")}
          />
        </div>
        {expand && (
          <>
            <span
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 700,
                letterSpacing: "0.025rem",
              }}
            >
              {userData?.username}
            </span>
            <span
              style={{
                color: theme.gray,
                fontSize: "1rem",
              }}
            >
              {userData?.companyName}
            </span>
            <span style={{ color: theme.gray, fontSize: "1rem" }}>
              {userData?.usertypeName}
            </span>
          </>
        )}
      </div>
      {/* Navigations Container */}
      <StyledNavigationItemWrapper>
        {nav.map((props) => {
          return <SidebarItem key={props.title} {...props} />;
        })}
      </StyledNavigationItemWrapper>
      <div
        className={[classes.footer, expand ? "expand" : ""].join(" ")}
        onClick={() => dispatch(toggleSidebar())}
      >
        {expand ? <BsChevronLeft /> : <BsChevronRight />}
      </div>
    </div>
  );
};

export default Sidebar;
